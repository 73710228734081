import React, { useState, useContext, useEffect, useRef } from "react";
import { AiOutlinePlus, AiFillDelete } from "react-icons/ai";
import { LuPanelLeftClose } from "react-icons/lu";
import { FiUser, FiMessageSquare } from "react-icons/fi";
import { SlOptions } from "react-icons/sl";
import { ContextApp } from "../utils/Context";
import iconSideBar from "../images/LogoMYO.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { fetchChatHistory } from "../services/uploadFilesApi";
import FileManagementModal from "./modal/FileManagementModal";
import { fetchTopics, deleteTopic } from "../services/topicApi";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function LeftNav() {
  const {
    setShowSlide,
    showSlide,
    loadChatHistoryForTopic,
    topics,
    deleteTopicFromList,
    reloadTopicsTrigger,
    setReloadTopicsTrigger,
    setTopics,
    resetChat,
    isWaitingForResponse,
    isGuest,
  } = useContext(ContextApp);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showFileManagementModal, setShowFileManagementModal] = useState(false);
  const dropdownRef = useRef(null);

  //logout
  const handleLogout = () => {
    Cookies.remove("accessToken");
    console.log("Cookies al deslogearse: ", Cookies.get());
    navigate("/login");
  };

  // funcion para cerrar el menu al clickear afuera
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDeleteTopic = async (topic) => {
    try {
      await deleteTopic(topic);
      setReloadTopicsTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error al eliminar el tema:", error);
    }
  };

  const handleTopicClick = async (topic) => {
    try {
      const history = await fetchChatHistory(topic);
      console.log(history);
    } catch (error) {
      console.error(
        "Error al intentar cargar el historial del chat para el tópico:",
        topic,
        error
      );
    }
  };

  return (
    // top section
    <div key={reloadTopicsTrigger}>
      <div
        className={
          !showSlide
            ? "flex flex-col h-screen bg-customBlue w-[300px] border-r border-gray-500 lg:flex items-center justify-between text-white translate-x-0"
            : "hidden"
        }
      >
        {showFileManagementModal && (
          <FileManagementModal
            onClose={() => setShowFileManagementModal(false)}
          />
        )}

        {/* Top section - Logo y botones */}
        <div className="flex flex-col items-center w-full">
          {/* Imagen centrada en la parte superior */}
          <img
            src={iconSideBar}
            alt="Icono del Sidebar"
            className="w-sidebar-icon h-sidebar-icon mt-4 mb-2"
          />
          {/* middletop section */}
          <div className="w-full px-4 mt-5 flex justify-between items-center">
            {/* Chat Nuevo */}
            <span
              className="border border-white rounded w-[calc(100%-4rem)] py-2 text-xs flex gap-1 items-center justify-center cursor-pointer"
              onClick={resetChat}
            >
              <AiOutlinePlus fontSize={18} />
              Chat Nuevo
            </span>

            {/* Close Sidebar */}
            <span
              className="border border-white rounded px-3 py-[9px] flex items-center justify-center cursor-pointer"
              title="Close sidebar"
              onClick={() => setShowSlide(!showSlide)}
            >
              <LuPanelLeftClose />
            </span>
          </div>
          <div>
            {topics.map((topic, index) => (
              <div
                key={index}
                className={`rounded w-full py-3 px-2 text-xs my-2 flex items-center justify-between cursor-pointer hover:bg-gray-800 transition-all duration-300 overflow-hidden truncate whitespace-nowrap relative ${
                  isWaitingForResponse ? "opacity-50 pointer-events-none" : ""
                }`}
                onClick={() => loadChatHistoryForTopic(topic)}
              >
                <span
                  className="flex-1 truncate hover:text-red-500"
                  style={{ transition: "color 0.3s ease" }}
                >
                  {topic}
                </span>
                <AiFillDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTopicFromList(topic);
                  }}
                  className="text-red-500 cursor-pointer ml-4 hover:text-gray-900"
                  style={{ transition: "color 0.3s ease" }}
                  onMouseEnter={(e) =>
                    e.currentTarget.parentNode.firstChild.classList.add(
                      "text-red-500"
                    )
                  }
                  onMouseLeave={(e) =>
                    e.currentTarget.parentNode.firstChild.classList.remove(
                      "text-red-500"
                    )
                  }
                />
              </div>
            ))}
          </div>
        </div>
        {/* middletop section */}

        {/* middle section  */}
        <div className="overflow-y-auto"></div>
        {/* bottom section  */}
        <div className="relative w-full bg-customFooter border-t border-gray-600 flex flex-col gap-2 items-center justify-center py-5">
          <span className="rounded w-full py-2 px-2 mt-5 text-xs flex gap-1 items-center justify-between cursor-pointer transition-all duration-300">
            {isGuest ? (
              <>
                <span className="flex gap-2 ml-3 mr-1 items-center justify-center text-sm font-bold text-gray-200">
                  Invitado
                </span>
                <button
                  className="rounded-md px-4 py-2 text-sm font-medium bg-gray-600 hover:bg-gray-700 text-white uppercase cursor-pointer transition-colors duration-300"
                  onClick={() => navigate("/login")}
                >
                  Iniciar Sesión
                </button>
              </>
            ) : (
              <>
                <span className="flex gap-2 ml-3 mr-1 items-center justify-center text-sm font-bold text-white">
                  <FontAwesomeIcon icon={faUser} />
                  Usuario
                </span>
                <span
                  className="rounded-md px-4 py-2 text-sm font-medium hover:bg-gray-800 uppercase text-gray-500 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowDropdown(!showDropdown);
                  }}
                >
                  <SlOptions />
                </span>
              </>
            )}
          </span>
          {showDropdown && !isGuest && (
            <div
              ref={dropdownRef}
              className="absolute bottom-[60px] right-0 translate-x-[20%] w-48 bg-white text-black rounded shadow-md z-10"
            >
              <ul>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    console.log("Intentando abrir el modal");
                    setShowFileManagementModal(true);
                    setShowDropdown(false);
                  }}
                >
                  Administración de Archivos
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={handleLogout}
                >
                  Cerrar Sesión
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeftNav;
