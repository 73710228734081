import React, { useCallback, useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDropzone } from "react-dropzone";
import {
  getUserDocuments,
  uploadMultipleFiles,
  deleteFile,
} from "../../services/uploadFilesApi";
import { RiUpload2Line, RiDeleteBin6Line, RiFileAddLine } from "react-icons/ri";
import Pdf from "../../images/pdf.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FileManagementModal({ onClose }) {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userDocuments, setUserDocuments] = useState([]);
  const [refreshDocuments, setRefreshDocuments] = useState(false);

  useEffect(() => {
    const fetchUserDocuments = async () => {
      try {
        const documents = await getUserDocuments();
        setUserDocuments(documents);
      } catch (error) {
        console.error(error);
        toast.dismiss();
        toast.error("Error al obtener documentos.");
      }
    };

    fetchUserDocuments();
  }, [refreshDocuments]);

  //seleccionar archivo
  const handleFileSelect = (event) => {
    const newFiles = Array.from(event.target.files).filter(
      (file) => file.type === "application/pdf"
    );
    if (event.target.files.length !== newFiles.length) {
      toast.info("Solo se permiten archivos PDF.");
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeFile = (index) => {
    const newFiles = selectedFiles.filter(
      (_, fileIndex) => fileIndex !== index
    );
    setSelectedFiles(newFiles);
  };

  //subir archivos
  const uploadSelectedFiles = async () => {
    toast.dismiss();
    const toastId = toast.warning("Cargando archivos, por favor espera...", {
      autoClose: false,
    });

    try {
      const result = await uploadMultipleFiles(selectedFiles);
      console.log("Archivos cargados con éxito:", result);
      toast.dismiss(toastId);
      toast.success("Archivos cargados con éxito.");
      setSelectedFiles([]);
      setRefreshDocuments(!refreshDocuments);
    } catch (error) {
      console.error("Error al intentar cargar los archivos:", error);
      toast.dismiss();
      toast.error("Error al cargar archivos.");
    }
  };

  //para el drag n drop
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "application/pdf",
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
  });

  //eliminar archivos
  const handleDeleteFile = async (fileHash, collectionName) => {
    toast.dismiss();
    const toastId = toast.warning("Eliminando archivos, por favor espera...", {
      autoClose: false,
    });
    try {
      await deleteFile(fileHash, collectionName);
      toast.dismiss(toastId);
      toast.success("Archivo eliminado con éxito");
      setRefreshDocuments((prev) => !prev);
    } catch (error) {
      toast.dismiss();
      toast.error("Error al eliminar el archivo");
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-backdrop absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-5 rounded-lg w-full max-w-2xl mx-4 md:mx-8 h-3/4 overflow-auto">
        <div className="text-center mb-4">
          <h2 className="text-lg font-bold">
            Menú de Administración de Archivos
          </h2>
        </div>

        {userDocuments.length > 0 ? (
          <div className="previously-uploaded-documents mb-4">
            <h3 className="font-semibold mb-2">Documentos Subidos:</h3>
            <div className="grid grid-cols-2 gap-4">
              {userDocuments.map((doc) => (
              <div
              key={doc.FILE_HASH}
              className="flex items-center space-x-2 bg-gray-100 p-2 rounded"
            >
              <img src={Pdf} alt="PDF" className="h-16 w-16" />
              <div className="flex-grow">
                <div
                  className="text-gray-800 break-words" 
                  title={doc.FILE_NAME}
                >
                  {doc.FILE_NAME}
                </div>
                <div className="text-sm text-gray-500">
                  {doc.COLLECTION_NAME}
                </div>
              </div>
              <button
                onClick={() => handleDeleteFile(doc.FILE_HASH, doc.COLLECTION_NAME)}
                className="text-red-500 hover:text-red-700"
              >
                <RiDeleteBin6Line className="text-lg" />
              </button>
            </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className="text-center my-4 p-10 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer"
          >
            <input {...getInputProps()} accept="application/pdf" />
            <RiFileAddLine className="mx-auto text-gray-300" size="5em" />
            {isDragActive ? (
              <p className="text-gray-600 mt-4">Arrastra aquí</p>
            ) : (
              <>
                <p className="text-gray-600 mt-4">
                  No tienes documentos cargados aún.
                </p>
                <p className="text-gray-500">
                  Arrastra y suelta algunos archivos aquí, o haz clic para
                  seleccionar archivos
                </p>
              </>
            )}
          </div>
        )}

        {/* Boton para seleccionar archivos PDF */}
        <button
          onClick={() => fileInputRef.current && fileInputRef.current.click()}
          className="file-select-btn mb-2 p-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition duration-150 flex items-center gap-2"
        >
          <RiUpload2Line className="text-lg" /> Seleccionar PDF
        </button>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileSelect}
          ref={fileInputRef}
          style={{ display: "none" }}
          multiple
        />
        {/* Lista de los archivos seleccionados */}
        <ul className="selected-files-list w-full">
          {selectedFiles.map((file, index) => (
            <li
              key={index}
              className="file-name py-1 px-2 rounded bg-gray-100 my-1 flex justify-between items-center"
            >
              <span>
                {file.name} - {Math.round(file.size / 1024)} KB
              </span>
              <button
                onClick={() => removeFile(index)}
                className="text-red-500 hover:text-red-600"
              >
                &times; {/* Boton para eliminar los archivos */}
              </button>
            </li>
          ))}
        </ul>
        {/* Botom para subir los archivos */}
        <button
          onClick={uploadSelectedFiles}
          className={`mr-2 p-2 rounded-lg text-white transition duration-150 ${
            selectedFiles.length > 0
              ? "bg-green-500 hover:bg-green-700"
              : "bg-gray-500"
          }`}
          disabled={selectedFiles.length === 0}
        >
          Subir PDF
        </button>
        <button
          onClick={onClose}
          className="mt-4 py-2 px-4 rounded-lg bg-red-500 text-white hover:bg-red-700 transition duration-150"
        >
          Cerrar
        </button>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default FileManagementModal;
