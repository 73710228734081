import Cookies from "js-cookie";
import { jwtDecode } from 'jwt-decode';

export const uploadMultipleFiles = async (files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });

  const SCHEME = window.location.protocol;
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const API_URL = `${SCHEME}//${API_BASE_URL}/api/uploadfiles`;
  const token = Cookies.get("accessToken");
  const decodedToken = jwtDecode(token);


  const userGroups = decodedToken["cognito:groups"];
  if (!userGroups.includes("admin")) {
    throw new Error("Se requieren privilegios de administrador.");
  }

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Collection": "coleccion1" 
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Error al cargar el archivo");
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error al intentar enviar el archivo:", error);
    throw error;
  }
};


export const getUserDocuments = async () => {
  const SCHEME = window.location.protocol;
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  let API_URL = `${SCHEME}//${API_BASE_URL}/api/documents`;
  const token = Cookies.get("accessToken");
  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });

    if (!response.ok) {
      throw new Error("Error al obtener los documentos");
    }

    const documents = await response.json();
    return documents;
  } catch (error) {
    console.error("Error al intentar obtener los documentos:", error);
    throw error;
  }
};

export const fetchChatHistory = async (topic) => {
  const SCHEME = window.location.protocol;
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const API_URL = `${SCHEME}//${API_BASE_URL}/api/history`;

  const token = Cookies.get("accessToken"); 

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "Topic": topic 
      }
    });

    if (!response.ok) {
      throw new Error("Error al cargar el historial del chat");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error al intentar obtener el historial del chat:", error);
    throw error;
  }
};



export const deleteFile = async (fileHash, collectionName) => {
  const SCHEME = window.location.protocol;
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  let API_URL = `${SCHEME}//${API_BASE_URL}/api/deletefile`;
  
  const token = Cookies.get("accessToken");
  if (!token) {
    throw new Error("No se encontró el token de acceso. Por favor, inicie sesión nuevamente.");
  }
  
  const decodedToken = jwtDecode(token);
  const userGroups = decodedToken["cognito:groups"];
  if (!userGroups || !userGroups.includes("admin")) {
    throw new Error("Usuario no autorizado. Se requieren privilegios de administrador.");
  }

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` 
      },
      body: JSON.stringify({
        file_hash: fileHash,
        collection: collectionName,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error al eliminar el archivo: ${errorText}`);
    }
    return true;
  } catch (error) {
    console.error('Error al eliminar el archivo:', error);
    throw error;
  }
};
