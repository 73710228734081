import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextApp } from "../utils/Context";
import IconChatContainer from "../images/iconchatcontainer.svg";
import iconBot from "../images/botIcon.svg";
import iconUser from "../images/userIcon.svg";

function Chat() {
  const {
    message,
    setMessage,
    showSlide,
    currentTopic,
    loadChatHistoryForTopic,
    loadChatHistory,
  } = useContext(ContextApp);
  const endOfMessagesRef = useRef(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [hasLoadedHistory, setHasLoadedHistory] = useState(false);
  const messagesContainerRef = useRef(null);
  const [shouldLoadHistory, setShouldLoadHistory] = useState(false);
  <div
    ref={messagesContainerRef}
    className="w-full h-[85%] overflow-hidden overflow-y-scroll px-2"
    onScroll={handleUserScroll}
  ></div>;

  const chatClass = `w-full h-[85%] overflow-hidden overflow-y-scroll ${
    showSlide ? "pl-12" : "pl-2"
  } pr-2`;

  // useEffect(() => {
  //   if (!hasLoadedHistory) {
  //     loadChatHistory('usuario1', 'topico1');
  //     setHasLoadedHistory(true);
  //   }
  // }, [loadChatHistory, hasLoadedHistory]);

  const handleUserScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } =
        messagesContainerRef.current;
      if (scrollHeight - scrollTop > clientHeight * 1.5) {
        setShouldScrollToBottom(false);
      } else {
        setShouldScrollToBottom(true);
      }
    }
  };
  useEffect(() => {
    if (shouldScrollToBottom) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [message, shouldScrollToBottom]);

  useEffect(() => {
    if (currentTopic && shouldLoadHistory && !hasLoadedHistory) {
      loadChatHistoryForTopic(currentTopic);
      setHasLoadedHistory(true);
      setShouldLoadHistory(false);
      console.log("Historial cargado para:", currentTopic);
    }
  }, [currentTopic, shouldLoadHistory, loadChatHistoryForTopic]);

  return (
    <div
      ref={messagesContainerRef}
      className={chatClass}
      onScroll={handleUserScroll}
    >
      {message && message.length > 0 ? (
        <div className="flex flex-col w-full justify-end pb-4">
          {message.map((msg, i) => (
            <div
              key={i}
              className={`w-full flex items-start mb-4 ${
                msg.isBot ? "bg-[#F3F3F3]" : "bg-white"
              }`}
            >
              <div
                className="flex w-full justify-center my-4 mx-auto"
                style={{ maxWidth: "45rem" }}
              >
                <div className="flex items-start w-full">
                  <img
                    src={msg.isBot ? iconBot : iconUser}
                    alt={msg.isBot ? "Bot" : "User"}
                    className="w-8 h-8 object-cover mr-2 mt-1"
                  />
                  <p
                    className="whitespace-pre-wrap flex-1 text-justify"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                      {msg.isBot && msg.text.startsWith(' ') ? msg.text.slice(1) : msg.text}
                  </p>
                </div>
              </div>
            </div>
          ))}
          {/* ref al final de los mensajes para el auto-scroll */}
          <div ref={endOfMessagesRef} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <img
            src={IconChatContainer}
            alt="Help Icon"
            className="w-20 h-20 mb-2"
          />
          <p className="text-black font-normal text-lg">
            ¿Cómo puedo ayudarte hoy?
          </p>
        </div>
      )}
    </div>
  );
}
export default Chat;
