import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import Cookies from "js-cookie";
import { fetchTopics, deleteTopic } from "../services/topicApi";
import { fetchChatHistory } from "../services/uploadFilesApi";

export const ContextApp = createContext();

const AppContext = ({ children }) => {
  const [showSlide, setShowSlide] = useState(false);
  const [chatValue, setChatValue] = useState("");
  const [message, setMessage] = useState(() => {
    const storedMessages = localStorage.getItem("chatHistory");
    return storedMessages ? JSON.parse(storedMessages) : [];
  });
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const ws = useRef(null);
  const [currentTopic, setCurrentTopic] = useState(null);
  const [topics, setTopics] = useState([]);
  const [reloadTopicsTrigger, setReloadTopicsTrigger] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isGuest, setIsGuest] = useState(true);
  const [shouldLoadHistory, setShouldLoadHistory] = useState(false);
  const [canChangeTopic, setCanChangeTopic] = useState(true);

  const triggerLoadHistory = (load) => {
    setShouldLoadHistory(load);
};

const loadTopics = async () => {
  if (isGuest) {
    console.log("Acceso denegado para cargar temas para usuarios invitados.");
    return;
  }
  try {
    const fetchedTopics = await fetchTopics();
    setTopics(fetchedTopics);
  } catch (error) {
    console.error("Error al cargar los tópicos:", error);
  }
};

useEffect(() => {
  loadTopics();
}, [reloadTopicsTrigger, isLoggedIn, isGuest]);

  const deleteTopicFromList = async (topic) => {
    try {
      await deleteTopic(topic);
      await loadTopics();
      if (currentTopic === topic) {
        resetChat();
      }
    } catch (error) {
      console.error("Error al eliminar el tema:", error);
    }
  };

useEffect(() => {
  if (isLoggedIn) {
    loadTopics();
    console.log("Cargando tópicos");
    
  }
}, [isLoggedIn]);


  const getWebSocketURL = () => {
    const wsScheme = window.location.protocol.includes("https") ? "wss" : "ws";
    const WS_URL = process.env.REACT_APP_WS_URL || "";
    return `${wsScheme}://${WS_URL}/ws`;
  };

  const setupWebSocket = () => {
    const wsPath = getWebSocketURL();
    if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
      ws.current = new WebSocket(wsPath);
      ws.current.onopen = () => console.log("Conexión WebSocket abierta");

      ws.current.onmessage = (event) => {
        console.log("Mensaje recibido del ws:", event.data);
        const cleanMessage = JSON.parse(event.data);

        setMessage((prevMessages) => {
          const lastMessage =
            prevMessages.length > 0
              ? prevMessages[prevMessages.length - 1]
              : null;
          if (lastMessage && lastMessage.isBot) {
            return [
              ...prevMessages.slice(0, prevMessages.length - 1),
              { ...lastMessage, text: lastMessage.text + cleanMessage },
            ];
          } else {
            return [...prevMessages, { text: cleanMessage, isBot: true }];
          }
        });
        clearTimeout(ws.current.timeout);
        ws.current.timeout = setTimeout(() => {
          setIsWaitingForResponse(false);
          loadTopics();
        }, 1000);
      };

      ws.current.onerror = (error) =>
        console.error("Error en la conexión WebSocket:", error);
    }
  };

  useEffect(() => {
    setupWebSocket();
    return () => {
      if (ws.current) {
        ws.current.close();
      }
      clearTimeout(ws.current?.timeout);
    };
  }, []);

  const handleSend = async (inputText = chatValue.trim()) => {
    if (!inputText || !ws.current || ws.current.readyState !== WebSocket.OPEN) {
        console.log("No hay mensaje o el WebSocket no está abierto");
        return;
    }

    const guestToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjVsUmhudGpWekNRS0hWN044Z091SUNYYXRpTU9BUEVZT3BBY01jc1lIdWM9In0.eyJzdWIiOiJHVUVTVF9VU0VSIiwiY29nbml0bzpncm91cHMiOlsiR1VFU1RfR1JPVVAiXSwiaXNzIjoiZnJvbnQiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJleHAiOjE3MTQzOTc3MjgsImlhdCI6MTcxMzc5MjkyOCwidXNlcm5hbWUiOiJHVUVTVF9VU0VSIn0.A0shWPKZgsmXzNHTXzdvL41GvYYTh-DEgfKo8nuONYWrlXLJl9YA7fg86UMzdBvbCsoNkPPsbWl_1cZCJQG77ceyOTlzFZwwJpiuUe7ekHnWNWkUcf39gAM4LesuytbZiaHoClelR6zJsA3Qgp_dgcCBM4Jppro1GGYOxuB6UuIrmILA4zEx2-PzhxnBqP0KDFuDncysOLyeX4qtmWCCg8QZ5HltjjrolVLIYU76UUFi6Img60GEi5CLYeSdBjDl0GKXgJZCy4BQarp2NhZWOnD7KniFbNVmRTGrj4L6wPqu54pXwNlfO0K3a4LiVdyjXI1Wfk9m4CBUxtIFwXzw-Q";
    const accessToken = Cookies.get("accessToken");
    const authHeader = isGuest ? `Bearer ${guestToken}` : `Bearer ${accessToken}`;

    let topic = currentTopic;
    if (!topic) {
        const topicSuffix = inputText.length > 15 ? "..." : "";
        topic = inputText.substring(0, 15) + topicSuffix;
        setCurrentTopic(topic);
        triggerLoadHistory(true); 
    } else {
        triggerLoadHistory(false);
    }

    const messageData = {
        query: inputText,
        collection: "coleccion1",
        Authorization: authHeader,
        topic: topic,
    };
    console.log("message:", messageData);
    ws.current.send(JSON.stringify(messageData));
    setIsWaitingForResponse(true);
    setChatValue("");
    setMessage((prevMessages) => [
        ...prevMessages,
        { text: inputText, isBot: false },
    ]);
};



  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const loadChatHistoryForTopic = async (topic) => {
    setCurrentTopic(topic);
    try {
      const history = await fetchChatHistory(topic);
      setMessage(
        history.map((msg) => ({
          text: msg.content,
          isBot: msg.type !== "human",
        }))
      );
    } catch (error) {
      console.error("Error al cargar el historial del chat:", error);
    }
  };

  const resetChat = () => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.close();
    }

    setCurrentTopic(null);
    setMessage([]);

    setTimeout(() => {
      setupWebSocket();
    }, 300);
  };
  return (
    <ContextApp.Provider
      value={{
        showSlide,
        setShowSlide,
        chatValue,
        setChatValue,
        handleSend,
        message,
        handleKeyPress,
        isWaitingForResponse,
        loadChatHistoryForTopic,
        currentTopic,
        topics,
        deleteTopicFromList,
        loadTopics,
        reloadTopicsTrigger,
        setReloadTopicsTrigger,
        resetChat,  
        isLoggedIn, 
        setIsLoggedIn,
        isGuest, 
        setIsGuest,
      }}
    >
      {children}
    </ContextApp.Provider>
  );
};

export default AppContext;