import Cookies from "js-cookie";

export const fetchTopics = async () => {
  const SCHEME = window.location.protocol;
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  let API_URL = `${SCHEME}//${API_BASE_URL}/api/topics`;

  const token = Cookies.get("accessToken");
  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error al cargar los tópicos de chat");
    }

    const topics = await response.json();
    return topics;
  } catch (error) {
    console.error("Error al intentar cargar los tópicos de chat:", error);
    throw error;
  }
};


export const deleteTopic = async (topic) => {

  const SCHEME = window.location.protocol;
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  let API_URL = `${SCHEME}//${API_BASE_URL}/api/deletetopic`;
  const token = Cookies.get("accessToken");
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'topic': topic
    }
  });

  if (!response.ok) {
    throw new Error('Error al eliminar el tema');
  }

  return response.json();
};
