import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LogoImg from "../../images/LogoLogin.svg";
import login from "../../services/loginApi";
import { toast } from "react-toastify";
import { ContextApp } from "../../utils/Context";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';


function Login() {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setIsLoggedIn, setIsGuest } = useContext(ContextApp);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    console.log("process env:", process.env.REACT_APP_API_URL);
    toast.info("Intentando iniciar sesión...");
    try {
      await login(user, password);
      toast.dismiss();
      toast.success("Logeado exitosamente!");
      setIsLoggedIn(true);
      setIsGuest(false);
      navigate("/");
    } catch (error) {
      setErrorMessage(
        "Falló el inicio de sesión. Por favor, verifica tus credenciales."
      );
      toast.dismiss();
      toast.error(
        "Error al intentar iniciar sesión. Por favor, verifica tus credenciales.",
        { autoClose: 5000 }
      );
      console.error("Error en el inicio de sesión:", error);
    }
  };

  return (
    <div className="flex justify-center items-center bg-customBlue min-h-screen">
      <div className="w-full max-w-md mx-auto bg-[#f8f8ff] shadow-md rounded-2xl p-6">
        <img src={LogoImg} className="h-[119px] w-[336px] mx-auto" alt="Logo" />
        <div className="text-center mt-3">
          <p className="text-lg font-medium text-black font-sans">
            Bienvenida / Bienvenido
          </p>
        </div>
        <div className="text-center">
          <p className="text-sm font-light text-black font-sans">
            Para ingresar a la plataforma
          </p>
          {errorMessage && (
            <p className="text-sm font-light text-red-500">{errorMessage}</p>
          )}
        </div>
        <form onSubmit={handleSubmit} className="mt-5">
          <input
            type="text"
            className="border-2 border-customIntermediate focus:border-customIntermediate focus:ring-0 mt-5 mb-4 w-full px-4 py-2"
            placeholder="Usuario"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            required
          />
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              className="border-2 border-customIntermediate focus:border-customIntermediate focus:ring-0 mt-4 mb-5 w-full px-4 py-2"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
         <button
  type="button"
  onClick={togglePasswordVisibility}
  className="absolute inset-y-0 right-0 pr-3 flex items-center"
  aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
>
  {showPassword ? <EyeSlashIcon className="h-5 w-5 text-gray-700"/> : <EyeIcon className="h-5 w-5 text-gray-700"/>}
</button>

          </div>
          <button
            type="submit"
            className="bg-customIntermediate hover:bg-customIntermediate text-white w-full py-2 mt-4"
          >
            Ingresar
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
