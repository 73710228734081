import Cookies from 'js-cookie';

const login = async (username, password) => {
  try {

    const SCHEME = window.location.protocol;
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    let API_URL = `${SCHEME}//${API_BASE_URL}/login`;
    const response = await fetch('https://api.chatbot.mdevs.net/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const { AccessToken } = data;

    Cookies.set('accessToken', AccessToken, { expires: 1 }); 
    console.log('Token: ',Cookies.get())
    return AccessToken;
  } catch (error) {
    console.error('Error al realizar la petición de inicio de sesión:', error);
    throw error;
  }
};

export default login;
